import {Button, ButtonGroup, Table} from "react-bootstrap";
import {ApiContext, arrayEnumSort, trans} from "../../lib";
import React, {useContext, useState} from "react";
import {Animal, CeresApi, SelfProduction as SelfProductionModel, SelfProductionAgeEnum} from "../../api";
import {AddEdit} from "./AddEdit";
import {faEdit, faRemove} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type SelfProductionProps = {
  collection: SelfProductionModel[],
  animal: Animal
}

export const View = (props: SelfProductionProps) => {
  const [collection, setCollection]: [SelfProductionModel[], any] = useState(arrayEnumSort(props.collection, SelfProductionAgeEnum, 'age'));
  const apiClient: CeresApi = useContext(ApiContext)

  const onDelete = async (id: Pick<SelfProductionModel, 'id'>) => {
    await apiClient.selfProduction.deleteCeresapiSelfproductionDelete(props.animal.id!, id as string);

    setCollection([
      ...arrayEnumSort(
        collection
          .filter((model: SelfProductionModel): boolean => model.id !== id)
          .map((model: SelfProductionModel) => ({...model})),
        SelfProductionAgeEnum,
        'age'
      )
    ])
  }

  const onSave = async (model: SelfProductionModel) => {
    let response: SelfProductionModel;
    let temp = [...collection];

    if (model.id) {
      await apiClient.selfProduction.putCeresapiSelfproductionPut(props.animal.id!, model.id, model);
      temp[temp.findIndex((item: SelfProductionModel) => item.id === model.id)] = model;
    } else {
      response = await apiClient.selfProduction.postCeresapiSelfproductionPost(props.animal.id!, model);
      temp.push(response);
    }
    setCollection([...arrayEnumSort([...temp], SelfProductionAgeEnum, 'age')]);
  }

  return (
    <>
      <AddEdit model={{
        weight: 0,
        woolWeight: 0,
        milk20DaysVol: 0,
        milkMulsVol: 0,
        milkLactationVol: 0,
      }} excludedAgeOptions={collection.map((model: SelfProductionModel) => model.age)} onSave={onSave}/>

      <Table responsive bordered striped className="text-center" style={{overflow: "visible"}}>
        <thead>
        <tr>
          <th rowSpan={2}>{trans('age')}</th>
          <th rowSpan={2}>{trans('weight')}</th>
          <th rowSpan={2}>{trans('woolWeight')}</th>
          <th colSpan={4}>{trans('milkProduction')}</th>
        </tr>
        <tr>
          <th>{trans('milk20DaysVol')}</th>
          <th>{trans('milkMulsVol')}</th>
          <th>{trans('milkLactationVol')}</th>
          <th>{trans('actions')}</th>
        </tr>
        </thead>
        <tbody>
        {collection && collection.map((model: SelfProductionModel, index: number) =>
          <tr key={model.id}>
            <td>{model.age ? trans(model.age) : model.age}</td>
            <td>{model.weight}</td>
            <td>{model.woolWeight}</td>
            <td>{model.milk20DaysVol}</td>
            <td>{model.milkMulsVol}</td>
            <td>{model.milkLactationVol}</td>
            <td>
              <ButtonGroup>
                <AddEdit
                  model={model}
                  excludedAgeOptions={collection.map((model: SelfProductionModel) => model.age)}
                  onSave={onSave}
                  icon={faEdit}
                />
                <Button size="sm" variant="outline-danger" onClick={onDelete.bind(null, model.id)}>
                  <FontAwesomeIcon icon={faRemove}/>
                </Button>
              </ButtonGroup>
            </td>
          </tr>
        )}
        </tbody>
      </Table>
    </>
  )
}

export default View;