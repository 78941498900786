import React, {useState} from "react";
import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import {trans} from "../../lib";
import {ActionMeta, OnChangeValue} from "react-select/dist/declarations/src/types";
import {SelfProduction, SelfProductionAgeEnum} from "../../api";
import {IconDefinition} from "@fortawesome/free-regular-svg-icons";

type AddProps = {
  model: SelfProduction,
  onSave: (model: SelfProduction) => void,
  excludedAgeOptions?: (undefined | SelfProductionAgeEnum)[],
  icon?: IconDefinition,
}

export const AddEdit = (props: AddProps) => {
  const [show, setShow] = useState(false);
  const [model, setModel]: [SelfProduction, any] = React.useState(props.model)
  const [error, setError] = useState(null)

  const onSelectChange = (newValue: OnChangeValue<any, any>, actionMeta: ActionMeta<any>) => {
    setModel({...model, [actionMeta.name]: newValue.value})
  }

  const onChange = (e) => {
    let value = e.target.value;
    value = e.target.type === 'number' && value.length ? parseInt(value) : value

    setModel({...model, [e.target.name]: value})
  }

  const onSave = async () => {
    try {
      await props.onSave(model);
      setShow(false);
    } catch (e) {
      setError(e.body?.error ?? e.message)
    }
  }

  return (
    <>
      <Button size="sm" variant="outline-success float-end" onClick={setShow.bind(null, true)}>
        <FontAwesomeIcon icon={props.icon ?? faAdd}/>
      </Button>

      <Modal show={show} onHide={setShow.bind(null, false)}>
        <Modal.Header closeButton>
          <Modal.Title>{trans('add new selfproduction')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant={"danger"}>{error}</Alert>}
          <Form>
            <Row>
              <Col xs={12} sm={12} xl={12} xxl={6} lg={12}>
                <label>{trans('age')}</label>
                <Select
                  name="age"
                  onChange={onSelectChange}
                  value={{
                    label: trans(model.age ?? 'selectAge'),
                    value: model.age ?? ''
                  }}
                  options={
                    Object.keys(SelfProductionAgeEnum)
                      .filter((key: SelfProductionAgeEnum) => !props.excludedAgeOptions?.includes(key))
                      .map((key: SelfProductionAgeEnum) => ({
                        label: trans(key),
                        value: key
                      }))
                  }
                />
              </Col>
              <Col xs={12} sm={12} xl={12} xxl={6} lg={12}>
                <Form.FloatingLabel label={trans("weight")}>
                  <Form.Control onChange={onChange} name="weight" type={"number"} value={model.weight ?? 0}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={12} xxl={6} lg={12}>
                <Form.FloatingLabel label={trans("woolWeight")}>
                  <Form.Control onChange={onChange} name="woolWeight" type={"number"} value={model.woolWeight ?? 0}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={12} xxl={6} lg={12}>
                <Form.FloatingLabel label={trans("milk20DaysVol")}>
                  <Form.Control onChange={onChange} name="milk20DaysVol" type={"number"}
                                value={model.milk20DaysVol ?? 0}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={12} xxl={6} lg={12}>
                <Form.FloatingLabel label={trans("milkMulsVol")}>
                  <Form.Control onChange={onChange} name="milkMulsVol" type={"number"} value={model.milkMulsVol ?? 0}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={12} xxl={6} lg={12}>
                <Form.FloatingLabel label={trans("milkLactationVol")}>
                  <Form.Control onChange={onChange} name="milkLactationVol" type={"number"}
                                value={model.milkLactationVol ?? 0}/>
                </Form.FloatingLabel>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>

          <Button size="sm" variant="outline-success" onClick={onSave}>
            {trans('save')}
          </Button>
          <Button size="sm" variant="outline-secondary" onClick={setShow.bind(null, false)}>
            {trans('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
