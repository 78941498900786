import React from "react";
import {Button, Card} from "react-bootstrap";
import {Outlet} from "react-router-dom";

export const Layout = () => {
  return (
    <React.Fragment>
      <Card className="mt-4">
        <Card.Body>
          <Outlet/>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}

export default Layout;
