import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Accordion, Button, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faArrowLeft, faEdit, faPrint, faDownload} from "@fortawesome/free-solid-svg-icons";
import {Animal, DocumentSexEnum} from "../api";
import {ApiContext, trans} from "../lib";
import {Details, Parents} from "./ViewElements";
import {View as BonareProductionView} from "./BonareProduction";
import {View as SelfProductionView} from "./SelfProduction";
import moment from "moment/moment";
import {AddEdit} from "./AddEdit";
import FileDownload from "js-file-download";
import printJS from "print-js";

export const View = () => {
  const [model, setData] = useState<Animal | any>({});
  const {id}: { id: string } = useParams();
  const apiClient = useContext(ApiContext)

  useEffect(() => {
    apiClient.animal.getCeresapiAnimalGet(id).then((response) => {
      setData(response);
    })
  }, [id, apiClient.animal])

  const onSave = async (response: Animal) => {
    setData({...model, ...response})
  }

  const onDownload = async () => {
    apiClient.pdf.getCeresapiPdfDownload(id).then((response) => {
      FileDownload(response, 'report.pdf');
    }).catch((error) => {
      console.error(error)
    })
  }

  const onPrint = () => {
    printJS(`${process.env.REACT_APP_API_URL}/api/v1/pdf/animals/${model.id}/download`)
  }

  return (
    <React.Fragment>
      <Card className="mb-2">
        <Card.Header>
          <Link to={"/animals"} className="me-2">
            <Button variant={"outline-secondary"} size="sm">
              <FontAwesomeIcon icon={faArrowLeft}/>
            </Button>
          </Link>
          { model && <AddEdit onSave={onSave} model={model} icon={faEdit}/> }
          <Link to={`${process.env.REACT_APP_API_URL}/api/v1/pdf/animals/${model.id}/download`} target="_blank">
            <Button variant={"outline-success"} size="sm" className="me-2 float-end">
              <FontAwesomeIcon icon={faDownload}/>
            </Button>
          </Link>
          <Button variant={"outline-info"} size="sm" className="me-2 float-end" onClick={onPrint}>
            <FontAwesomeIcon icon={faPrint}/>
          </Button>
        </Card.Header>
        <Card.Body>
          {model && model.id && <Details model={model}/>}
        </Card.Body>
      </Card>
      <Accordion flush>
        <Accordion.Item eventKey="parents" style={{background: "transparent"}}>
          <Accordion.Header>{trans('parents')}</Accordion.Header>
          <Accordion.Body className={"m-0 p-2"}>
            {model && model.id && (<Parents model={model}/>)}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="selfProduction">
          <Accordion.Header>{trans('self production')}</Accordion.Header>
          <Accordion.Body>
            {model && model.selfProduction && <SelfProductionView collection={model.selfProduction ?? []} animal={model}/>}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="bonareProduction">
          <Accordion.Header>{trans('bonare production')}</Accordion.Header>
          <Accordion.Body>
            {model && model.bonareProduction && <BonareProductionView collection={model.bonareProduction ?? []} animal={model}/>}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </React.Fragment>
  )
}

export default View;