import {t} from 'i18next';
import Cookie from 'js-cookie'
import {AxiosError} from "axios";

const useTrans = (string: string) => {
  return t(string.replace('*', '') as any)
}

export const normalizeFormControl = (event: any) => {
  let value: any = event.currentTarget.value;
  if (event.type === 'number') {
    value = parseInt(value);
    if (typeof value !== 'number') {
      value = 0;
    }
  }

  return value;
}

export const arrayEnumSort = (collection: any[], enumData: any, index: any) => {
  const keys = Object.keys(enumData);
  collection.sort((a, b) => {
    const typeIndexA = keys.indexOf(a[index]);
    const typeIndexB = keys.indexOf(b[index]);

    if (typeIndexA < typeIndexB) {
      return -1;
    }

    if (typeIndexA > typeIndexB) {
      return 1;
    }

    return 0;
  });

  return collection;
}

export const extractToken = () => {
  if (window.location.search && window.location.search.includes('token')) {
    const token = new URLSearchParams(window.location.search).get('token');
    Cookie.set('token', token as string);
  }
}

export {useTrans as trans};