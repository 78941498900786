/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SelfProduction } from '../models/SelfProduction';
import type { SelfProduction2 } from '../models/SelfProduction2';
import type { SelfProduction3 } from '../models/SelfProduction3';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SelfProductionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param animal
     * @param id
     * @returns SelfProduction Get one self production document records
     * @throws ApiError
     */
    public getCeresapiSelfproductionGet(
        animal: string,
        id: string,
    ): CancelablePromise<SelfProduction> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/animals/{animal}/self-productions/{id}',
            path: {
                'animal': animal,
                'id': id,
            },
        });
    }

    /**
     * @param animal
     * @param id
     * @param requestBody JSON Request body
     * @returns SelfProduction Serialized self production document records
     * @throws ApiError
     */
    public putCeresapiSelfproductionPut(
        animal: string,
        id: string,
        requestBody?: SelfProduction2,
    ): CancelablePromise<SelfProduction> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/animals/{animal}/self-productions/{id}',
            path: {
                'animal': animal,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param animal
     * @param id
     * @returns any Delete single self production document records
     * @throws ApiError
     */
    public deleteCeresapiSelfproductionDelete(
        animal: string,
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/animals/{animal}/self-productions/{id}',
            path: {
                'animal': animal,
                'id': id,
            },
        });
    }

    /**
     * @param animal
     * @returns SelfProduction Get all self production document records
     * @throws ApiError
     */
    public getCeresapiSelfproductionCget(
        animal: string,
    ): CancelablePromise<Array<SelfProduction>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/animals/{animal}/self-productions',
            path: {
                'animal': animal,
            },
        });
    }

    /**
     * @param animal
     * @param requestBody JSON Request body
     * @returns SelfProduction Serialized self production document records
     * @throws ApiError
     */
    public postCeresapiSelfproductionPost(
        animal: string,
        requestBody?: SelfProduction3,
    ): CancelablePromise<SelfProduction> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/animals/{animal}/self-productions',
            path: {
                'animal': animal,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
