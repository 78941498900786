/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AnimalService } from './services/AnimalService';
import { BonareProductionService } from './services/BonareProductionService';
import { DescendentService } from './services/DescendentService';
import { LoginService } from './services/LoginService';
import { PdfService } from './services/PdfService';
import { SelfProductionService } from './services/SelfProductionService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class CeresApi {

    public readonly animal: AnimalService;
    public readonly bonareProduction: BonareProductionService;
    public readonly descendent: DescendentService;
    public readonly login: LoginService;
    public readonly pdf: PdfService;
    public readonly selfProduction: SelfProductionService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.animal = new AnimalService(this.request);
        this.bonareProduction = new BonareProductionService(this.request);
        this.descendent = new DescendentService(this.request);
        this.login = new LoginService(this.request);
        this.pdf = new PdfService(this.request);
        this.selfProduction = new SelfProductionService(this.request);
    }
}

