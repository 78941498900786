import React, {useContext, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {Link, NavigateFunction, useNavigate} from "react-router-dom";
import {ApiContext} from "../../lib";
import Cookie from "js-cookie";
import {CeresApi} from "../../api/CeresApi";

export const Username = () => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    console.log(password);
    const [error, setError] = useState<boolean>(false);
    const apiClient: CeresApi = useContext<CeresApi>(ApiContext)
    const navigate:NavigateFunction = useNavigate()

    const onSubmit = async (event: any): Promise<void> => {
        event.preventDefault();
        apiClient.login.postLoginUsernameAuth({username: username, password: password})
            .catch((error): void => {
                setError(error.body.error ?? error.message)
            })
            .then((response): void => {
                console.log(response);
                if (response?.token) {
                    Cookie.set('token', response.token);
                    navigate('/animals')
                }
            })
    }

    const onChange = (callback: (value: any) => void, event: any) => callback(event.target.value)

    return (
        <div className="d-grid gap-2">
            <Form onSubmit={onSubmit}>
                <Form.FloatingLabel label={"username"}>
                    <Form.Control name={"username"} type="username" placeholder="Enter username" onChange={onChange.bind(null, setUsername)}/>
                </Form.FloatingLabel>
                <Form.FloatingLabel label={"password"}>
                    <Form.Control name={"password"} type="password" placeholder="Enter password" onChange={onChange.bind(null, setPassword)}/>
                </Form.FloatingLabel>

                <div className="d-grid gap-2">
                    {error && <Alert variant={"danger"}>{error}</Alert>}
                    <Button type={"submit"} variant="outline-success">Login via username</Button>
                    <Link to={"/login"} className="me-2 btn btn-outline-secondary">cancel</Link>
                </div>
            </Form>
        </div>
    )
}

export default Username;
