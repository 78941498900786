import {Col, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import React from "react";
import {Animal} from "../../api";
import {trans} from "../../lib";
import moment from "moment/moment";

type DetailsProps = {
  model: Animal,
  xs?: number,
  sm?: number,
  xl?: number,
  xxl?: number,
  lg?: number,
}

const defaultProps: DetailsProps = {
  model: {},
  xs: 12,
  sm: 12,
  xl: 6,
  xxl: 6,
  lg: 6
}

export const Details = (props: DetailsProps = defaultProps) => {
  console.log(props)
  return (
    <Row>
      <ListGroup
        variant={"flush"}
        as={Col}
        xs={props.xs ?? defaultProps.xs}
        sm={props.sm ?? defaultProps.sm}
        xl={props.xl ?? defaultProps.xl}
        xxl={props.xxl ?? defaultProps.xxl}
        lg={props.lg ?? defaultProps.lg}
      >
        <ListGroupItem>
          <strong>{trans('regNumber')}</strong> {props.model.regNumber ?? '-'}
        </ListGroupItem>
        <ListGroupItem>
          <strong>{trans('matriculNumber')}</strong> {props.model.matriculNumber ?? '-'}
        </ListGroupItem>
        <ListGroupItem>
          <strong>{trans('birthdate')}</strong> {moment(props.model.birthdate).format('YYYY-MM-DD') ?? '-'}
        </ListGroupItem>
      </ListGroup>
      <ListGroup
        variant={"flush"}
        as={Col}
        xs={props.xs ?? defaultProps.xs}
        sm={props.sm ?? defaultProps.sm}
        xl={props.xl ?? defaultProps.xl}
        xxl={props.xxl ?? defaultProps.xxl}
        lg={props.lg ?? defaultProps.lg}
      >
        <ListGroupItem>
          <strong>{trans('sex')}</strong> {props.model.sex ?? '-'}
        </ListGroupItem>
        <ListGroupItem>
          <strong>{trans('unipari')}</strong> {props.model.metadata?.unipari ? '✓' : '✗'}
        </ListGroupItem>
        <ListGroupItem>
          <strong>{trans('gemelari')}</strong> {props.model.metadata?.gemelari ? '✓' : '✗'}
        </ListGroupItem>
        <ListGroupItem>
          <strong>{trans('class')}</strong> {props.model.metadata?.class ?? '-'}
        </ListGroupItem>
      </ListGroup>
    </Row>
  )
}

export default Details;