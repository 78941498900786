import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ro',
    returnNull: false,
    // debug: true,
    detection: {
      order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie'], // To store the value in the cookie
      cookieMinutes: 10080, // Cookie expiration time in minutes (7 days)
    },
    resources: {
      en: {
        translations: require('./i18n/translations.en.json')
      },
      ro: {
        translations: require('./i18n/translations.ro.json')
      },
      ru: {
        translations: require('./i18n/translations.ru.json')
      }
    },
    interpolation: { escapeValue: false },
    ns: ['translations'],
    defaultNS: 'translations',
  }).then(r => {});

i18n.languages = ['en', 'ro', 'ru'];

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
