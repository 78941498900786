import React from 'react';
import './App.css';

import {ThemeProvider} from "react-bootstrap";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import List from "./Animal/List";
// import {Create} from "./Animal/Create";
import View from "./Animal/View";
import {apiClient, ApiContext, extractToken} from "./lib";
import {Layout as LoginLayout} from "./Animal/Login/Layout";
import Layout from "./Elements/Layout";
import Main from "./Animal/Login/Main";
import Phone from "./Animal/Login/Phone";
import Email from "./Animal/Login/Email";
import Code from "./Animal/Login/Code";
import Username from "./Animal/Login/Username";

const App: React.FC = () => {
  extractToken()

  return (
    <ApiContext.Provider value={apiClient}>
      <ThemeProvider>
        <script src="https://kit.fontawesome.com/c8e53928c7.js" crossOrigin="anonymous"/>

        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginLayout/>}>
              <Route path="" element={<Main/>}/>
              <Route path="phone" element={<Phone/>}/>
              <Route path="email" element={<Email/>}/>
              <Route path="code" element={<Code/>}/>
              <Route path="username" element={<Username/>}/>
            </Route>
            <Route path="/" element={<Layout/>}>
              <Route path="" element={<List/>}/>
              <Route path="animals" element={<List/>}/>
              <Route path="animals/:id" element={<View/>}/>
            </Route>
          </Routes>

        </BrowserRouter>
      </ThemeProvider>
    </ApiContext.Provider>
  )
}

export default App
