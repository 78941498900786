import Container from "react-bootstrap/Container";
import {Outlet} from "react-router-dom";
import NavBar from "./NavBar";

export const Layout = () => {
  return (
    <Container fluid className="p-3">
      <NavBar/>
      <Outlet/>
    </Container>
  )
}

export default Layout;
