import React, {useContext, useEffect, useState} from "react";
import {Animal, CeresApi, DocumentSexEnum} from "../api";
import {Alert, Button, Col, Form, InputGroup, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import {ApiContext, trans} from "../lib";
import {IconDefinition} from "@fortawesome/free-regular-svg-icons";
import moment from "moment/moment";
import lodash from "lodash";

type AddProps = {
  model: Animal;
  onSave: (model: Animal) => void,
  icon?: IconDefinition,
  showModal?: boolean;
}

export const AddEdit = (props: AddProps) => {
  const [show, setShow] = useState<boolean>(props.showModal ?? false);
  const [model, setModel]: [Animal, any] = useState(props.model)
  const [error, setError]: [Partial<Animal>, any] = useState(null)
  const [validated, setValidated] = useState(false);
  const apiClient: CeresApi = useContext(ApiContext)

  // useEffect(() => {
  //   setModel(props.model)
  //   setShow(props.showModal ?? false)
  // }, [props.model]);
  const onChange = (e) => {
    const path = e.target.name.split('.');
    const temp = {...model};
    switch (e.target.type) {
        case 'checkbox':
            lodash.set(temp, path, Boolean(e.target.checked));
            break;
        case 'number':
            lodash.set(temp, path, parseInt(e.target.value));
            break;
        default:
            lodash.set(temp, path, e.target.value);
    }

    setModel(temp)
  }

  const onSave = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();

      return;
    }

    setValidated(true);

    try {
      let response: Animal;
      if (!model.id) {
        response = await apiClient.animal.postCeresapiAnimalPost(model);
      } else {
        response = await apiClient.animal.putCeresapiAnimalPut(model.id, model);
      }
      if (!model.id) {
        setModel({
          birthdate: moment().format('YYYY-MM-DD'),
          sex: DocumentSexEnum.SEX_MALE
        })
      }
      setValidated(false)
      await props.onSave(response);
      setShow(false);
      setError(null)
    } catch (e) {
      setError(e.body.error ?? e.body ?? e.message)
      console.log('Create animal error', error, e);
    }
  }

  console.log('AddEdit', model, error, validated, show);
  console.log('AddEdit Props', props);
  return (
    <>
      <Button size="sm" variant="outline-success float-end" onClick={setShow.bind(null, true)}>
        <FontAwesomeIcon icon={props.icon ?? faAdd}/>
      </Button>

      <Modal show={show} onHide={setShow.bind(null, false)}>
        <Modal.Header closeButton>
          <Modal.Title>{trans('add new animal')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate={true} validated={validated}>
            <Row className={"mb-1"}>
              <Col xs={12} sm={12} xl={12} xxl={12} lg={12}>
                  <Form.FloatingLabel label={trans('*regNumber')}>
                    <Form.Control
                      name="regNumber"
                      type="text"
                      placeholder="regNumber"
                      isValid={error && !error.regNumber }
                      value={model.regNumber ?? ''}
                      onChange={onChange}
                      required={true}/>
                    {error && error.regNumber && <Form.Control.Feedback type="invalid" tooltip>{error.regNumber}</Form.Control.Feedback>}
                  </Form.FloatingLabel>
              </Col>

              <Col xs={12} sm={12} xl={12} xxl={6} lg={12}>
                <Form.FloatingLabel label={trans("*matriculNumber")}>
                  <Form.Control
                    name="matriculNumber"
                    type="text"
                    placeholder="matriculNumber"
                    value={model.matriculNumber ?? ''}
                    isValid={error && !error.matriculNumber }
                    onChange={onChange}
                    required={true}/>
                  {error && error.matriculNumber && <Form.Control.Feedback type="invalid" tooltip>{error.matriculNumber}</Form.Control.Feedback>}
                </Form.FloatingLabel>
              </Col>

              <Col xs={12} sm={12} xl={12} xxl={6} lg={12}>
                <Form.FloatingLabel label={trans("*birthdate")}>
                  <Form.Control
                    name="birthdate"
                    type="date"
                    placeholder="birthdate"
                    value={moment(model.birthdate).format('YYYY-MM-DD') ?? ''}
                    isValid={error && !error.birthdate }
                    onChange={onChange}
                    required={true}/>
                  {error && error.birthdate && <Form.Control.Feedback type="invalid" tooltip>{error.birthdate}</Form.Control.Feedback>}
                </Form.FloatingLabel>
              </Col>

              <Col xs={12} sm={12} xl={12} xxl={6} lg={12}>
                <Form.FloatingLabel label={trans("*sex")}>
                  <Form.Select
                    name="sex"
                    placeholder={trans("sex")}
                    aria-label="Sex"
                    onChange={onChange}
                    value={model.sex ?? DocumentSexEnum.SEX_MALE}>
                    <option value={DocumentSexEnum.SEX_MALE} defaultChecked={true}>{trans('male')}</option>
                    <option value={DocumentSexEnum.SEX_FEMALE}>{trans('female')}</option>
                  </Form.Select>
                </Form.FloatingLabel>
              </Col>

              <Col xs={12} sm={12} xl={12} xxl={6} lg={12}>
                <Form.FloatingLabel label={trans("class")}>
                  <Form.Control
                    name="metadata.class"
                    type="text"
                    placeholder="class"
                    value={model.metadata?.class ?? ''}
                    onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={12} xxl={6} lg={12}>
                <Form.Check
                    name="metadata.gemelari"
                    type="switch"
                    label="gemelari"
                    checked={model.metadata?.gemelari ?? false}
                    onChange={onChange}/>
              </Col>

              <Col xs={12} sm={12} xl={12} xxl={6} lg={12}>
                <Form.Check
                    name="metadata.unipari"
                    type="switch"
                    label="unipari"
                    checked={model.metadata?.unipari ?? false}
                    onChange={onChange}/>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>

          <Button size="sm" variant="outline-success" onClick={onSave}>
            {trans('save')}
          </Button>
          <Button size="sm" variant="outline-secondary" onClick={setShow.bind(null, false)}>
            {trans('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}