import {Animal, BonareProduction, CeresApi,} from "../../api";
import React, {useContext, useState} from "react";
import {ApiContext, trans} from "../../lib";
import {faEdit, faRemove} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, ButtonGroup, Table} from "react-bootstrap";
import moment from "moment";
import {AddEdit} from "./AddEdit";

type BonareProductionProps = {
  collection: BonareProduction[],
  animal: Animal,
}

const newModel: BonareProduction = {
  date: (new Date()).toJSON(),
  age: 0,
  woolDensity: 0,
  woolLength: 0,
  woolCrimp: 0,
  woolQuality: 0,
  woolUniformity: 0,
  woolExtension: 0,
  woolElasticity: 0,
  exterior: 0,
  constitution: 0,
  size: 0,
  usucColor: 0,
  generalNote: 0,
  weight: 0,
  woolWeight: 0,
  class: "",
}

export const View = (props: BonareProductionProps) => {
  const [collection, setCollection]: [BonareProduction[], any] = useState(props.collection);
  const apiClient: CeresApi = useContext(ApiContext)

  const onDelete = async (id: Pick<BonareProduction, 'id'>) => {
    await apiClient.bonareProduction.deleteCeresapiBonareproductionDelete(props.animal.id!, id as string);

    setCollection([
      ...collection
        .filter((model: BonareProduction): boolean => model.id !== id)
        .map((model: BonareProduction) => ({...model})),
    ])
  }

  const onSave = async (model: BonareProduction) => {
    let response: BonareProduction;
    let temp = [...collection];

    if (model.id) {
      await apiClient.bonareProduction.putCeresapiBonareproductionPut(props.animal.id!, model.id, model);
      temp[temp.findIndex((item: BonareProduction) => item.id === model.id)] = model;
    } else {
      response = await apiClient.bonareProduction.postCeresapiBonareproductionPost(props.animal.id!, model);
      temp.push(response);
    }
    setCollection([...temp]);
  }

  return (
    <>
      <AddEdit model={{...newModel}} onSave={onSave}/>
      <Table responsive bordered striped className="text-center"
             style={{overflow: "visible", fontSize: "75%", overflowX: "scroll", display: "block"}}>
        <thead>
        <tr>
          <th rowSpan={2}>{trans('bonareDate')}</th>
          <th rowSpan={2}>{trans('bonareAge')}</th>
          <th colSpan={8}>{trans('bonareWoolProperties')}</th>
          <th rowSpan={2}>{trans('bonareConstitution')}</th>
          <th rowSpan={2}>{trans('bonareSize')}</th>
          <th rowSpan={2}>{trans('bonareUsucColor')}</th>
          <th rowSpan={2}>{trans('bonareGeneralNote')}</th>
          <th rowSpan={2}>{trans('bonareWeight')}</th>
          <th rowSpan={2}>{trans('bonareWoolWeight')}</th>
          <th rowSpan={2}>{trans('bonareClass')}</th>
          <th rowSpan={2}></th>
        </tr>
        <tr>
          <th>{trans('bonareWoolDensity')}</th>
          <th>{trans('bonareWoolLength')}</th>
          <th>{trans('bonareWoolCrimp')}</th>
          <th>{trans('bonareWoolQuality')}</th>
          <th>{trans('bonareWoolUniformity')}</th>
          <th>{trans('bonareWoolExtension')}</th>
          <th>{trans('bonareWoolElasticity')}</th>
          <th>{trans('bonareExterior')}</th>
        </tr>
        </thead>
        <tbody>
        {collection && collection.map((model: BonareProduction, index: number) =>
          <tr key={model.id}>
            <td>{moment(model.date).format('YYYY-MM-DD')}</td>
            <td>{model.age}</td>
            <td>{model.woolDensity}</td>
            <td>{model.woolLength}</td>
            <td>{model.woolCrimp}</td>
            <td>{model.woolQuality}</td>
            <td>{model.woolUniformity}</td>
            <td>{model.woolExtension}</td>
            <td>{model.woolElasticity}</td>
            <td>{model.exterior}</td>
            <td>{model.constitution}</td>
            <td>{model.size}</td>
            <td>{model.usucColor}</td>
            <td>{model.generalNote}</td>
            <td>{model.weight}</td>
            <td>{model.woolWeight}</td>
            <td>{model.class}</td>
            <td>
              <ButtonGroup>
                <AddEdit model={model} onSave={onSave} icon={faEdit}/>
                <Button size="sm" variant="outline-danger" onClick={onDelete.bind(null, model.id)}>
                  <FontAwesomeIcon icon={faRemove}/>
                </Button>
              </ButtonGroup>
            </td>
          </tr>
        )}
        </tbody>
      </Table>
    </>
  )
}

export default View;
