import React, {useContext, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {Link, NavigateFunction, useNavigate} from "react-router-dom";
import {ApiContext} from "../../lib";
import Cookie from "js-cookie";
import {CeresApi} from "../../api";

export const Code = () => {
  const [code, setCode] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const apiClient: CeresApi = useContext<CeresApi>(ApiContext)
  const navigate:NavigateFunction = useNavigate()

  const onSubmit = async (event: any): Promise<void> => {
    event.preventDefault();
    apiClient.login.postLoginCodeAuth({code})
      .catch((error): void => {
        console.log(error);
      })
      .then((response): void => {
        console.log(response);
        Cookie.set('token', response.token);
        navigate('/animals')
      })
  }

  const onChange = (event: any) => setCode(event.target.value);

  return (
    <div className="d-grid gap-2">
      <Form onSubmit={onSubmit}>
        <Form.FloatingLabel label={"code"}>
          <Form.Control name={"code"} type="code" placeholder="Enter code" onChange={onChange}/>
        </Form.FloatingLabel>

        <div className="d-grid gap-2">
          {error && <Alert variant={"danger"}>{error}</Alert>}
          <Button type={"submit"} variant="outline-success">Login via code</Button>
          <Link to={"/login"} className="me-2 btn btn-outline-secondary">cancel</Link>
        </div>
      </Form>
    </div>
  )
}

export default Code;
