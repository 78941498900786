import React, {useContext, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {ApiContext} from "../../lib";

export const Phone = () => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(false);
  const apiClient = useContext(ApiContext)
  const navigate = useNavigate()

  const onSubmit = async (event: any) => {
    event.preventDefault();
    apiClient.login.postLoginCodeVerificationPhone({value: phone})
      .catch((error) => {
        console.log(error);
      })
      .then(() => navigate('/login/code'))
  }

  const onChange = (event: any) => setPhone(event.target.value);

  return (
    <div className="d-grid gap-2">
      <Form onSubmit={onSubmit}>
        <Form.FloatingLabel label={"phone"}>
          <Form.Control name={"phone"} type="phone" placeholder="Enter phone" onChange={onChange}/>
        </Form.FloatingLabel>

        <div className="d-grid gap-2">
          {error && <Alert variant={"danger"}>{error}</Alert>}
          <Button type={"submit"} variant="outline-success">send verification code</Button>
          <Link to={"/login"} className="me-2 btn btn-outline-secondary">cancel</Link>
        </div>
      </Form>
    </div>
  )
}

export default Phone;
