/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SelfProductionAgeEnum {
    ON_BORN_AGE = 'ON_BORN_AGE',
    DAYS_20_AGE = 'DAYS_20_AGE',
    INTARCARE_AGE = 'INTARCARE_AGE',
    MONTH_12_14_AGE = 'MONTH_12_14_AGE',
    MONTH_24_30_AGE = 'MONTH_24_30_AGE',
    MONTH_36_42_AGE = 'MONTH_36_42_AGE',
    MONTH_48_54_AGE = 'MONTH_48_54_AGE',
    MONTH_60_66_AGE = 'MONTH_60_66_AGE',
    MONTH_72_78_AGE = 'MONTH_72_78_AGE',
    MONTH_84_90_AGE = 'MONTH_84_90_AGE',
    MONTH_96_102_AGE = 'MONTH_96_102_AGE',
}
