import React from "react";
import {Button, Card} from "react-bootstrap";
import {Link} from "react-router-dom";

export const Main = () => {
  return (
    <div className="d-grid gap-2">
      <Button variant="success" href={`${process.env.REACT_APP_API_URL}/api/v1/connect/google`}>Google auth</Button>
      {/*<Link to={"/login/phone"} className={"btn btn-secondary"}>Phone</Link>*/}
      {/*<Link to={"/login/email"} className={"btn btn-secondary"}>Email</Link>*/}
      <Link to={"/login/username"} className={"btn btn-secondary"}>Username</Link>
    </div>
  )
}

export default Main;
