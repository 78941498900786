/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LoginService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Logout path.
     * This method do not any actions and used just to define path of logout process.
     * @returns any
     * @throws ApiError
     */
    public getLogout(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/logout',
        });
    }

    /**
     * Social authentication connect method.
     * This method is used for authentication via social network, to get right client and return redirect to social
     * network second part authentication page.
     * @returns any
     * @throws ApiError
     */
    public getConnectGoogle(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/connect/google',
        });
    }

    /**
     * Google check callback authentication endpoint.
     * This path/method is used just to describe routes and define authentication(s). The true authentication is
     * processing in security Authenticator service/class.
     * @returns any
     * @throws ApiError
     */
    public getConnectCheckGoogle(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/connect/check/google',
        });
    }

    /**
     * @param requestBody JSON Request body
     * @returns any
     * @throws ApiError
     */
    public postLoginCodeAuth(
        requestBody?: {
            /**
             * Code from email or sms
             */
            code?: string;
        },
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/login/code',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody JSON Request body
     * @returns any
     * @throws ApiError
     */
    public postLoginUsernameAuth(
        requestBody?: {
            /**
             * Username
             */
            username?: string;
            /**
             * Password
             */
            password?: string;
        },
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/login/username',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody JSON Request body
     * @returns any
     * @throws ApiError
     */
    public postLoginCodeVerificationPhone(
        requestBody?: {
            /**
             * Email or phone number
             */
            value?: string;
        },
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/login/verification/phone',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody JSON Request body
     * @returns any
     * @throws ApiError
     */
    public postLoginCodeVerificationEmail(
        requestBody?: {
            /**
             * Email or phone number
             */
            value?: string;
        },
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/login/verification/email',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
