/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PdfService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param idsArray List of IDs to download
     * @returns any
     * @throws ApiError
     */
    public getCeresapiPdfCdownload(
        idsArray: Array<string>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/pdf/animals/download',
            query: {
                'ids[]': idsArray,
            },
        });
    }

    /**
     * @param idsArray List of IDs to download
     * @returns any
     * @throws ApiError
     */
    public getCeresapiPdfCview(
        idsArray: Array<string>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/pdf/animals/view',
            query: {
                'ids[]': idsArray,
            },
        });
    }

    /**
     * @param idsArray List of IDs to download
     * @returns any
     * @throws ApiError
     */
    public getCeresapiPdfCprint(
        idsArray: Array<string>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/pdf/animals/print',
            query: {
                'ids[]': idsArray,
            },
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public getCeresapiPdfPrint(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/pdf/animals/{id}/print',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public getCeresapiPdfDownload(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/pdf/animals/{id}/download',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public getCeresapiPdfView(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/pdf/animals/{id}/view',
            path: {
                'id': id,
            },
        });
    }

}
