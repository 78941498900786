/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BonareProduction } from '../models/BonareProduction';
import type { BonareProduction2 } from '../models/BonareProduction2';
import type { BonareProduction3 } from '../models/BonareProduction3';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BonareProductionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param animal
     * @param id
     * @returns BonareProduction Get one bonare production document records
     * @throws ApiError
     */
    public getCeresapiBonareproductionGet(
        animal: string,
        id: string,
    ): CancelablePromise<BonareProduction> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/animals/{animal}/bonare-productions/{id}',
            path: {
                'animal': animal,
                'id': id,
            },
        });
    }

    /**
     * @param animal
     * @param id
     * @param requestBody JSON Request body
     * @returns BonareProduction Serialized bonare production document records
     * @throws ApiError
     */
    public putCeresapiBonareproductionPut(
        animal: string,
        id: string,
        requestBody?: BonareProduction2,
    ): CancelablePromise<BonareProduction> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/animals/{animal}/bonare-productions/{id}',
            path: {
                'animal': animal,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param animal
     * @param id
     * @returns any Delete single bonare production document records
     * @throws ApiError
     */
    public deleteCeresapiBonareproductionDelete(
        animal: string,
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/animals/{animal}/bonare-productions/{id}',
            path: {
                'animal': animal,
                'id': id,
            },
        });
    }

    /**
     * @param animal
     * @returns BonareProduction Get all bonare production document records
     * @throws ApiError
     */
    public getCeresapiBonareproductionCget(
        animal: string,
    ): CancelablePromise<Array<BonareProduction>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/animals/{animal}/bonare-productions',
            path: {
                'animal': animal,
            },
        });
    }

    /**
     * @param animal
     * @param requestBody JSON Request body
     * @returns BonareProduction Serialized bonare production document records
     * @throws ApiError
     */
    public postCeresapiBonareproductionPost(
        animal: string,
        requestBody?: BonareProduction3,
    ): CancelablePromise<BonareProduction> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/animals/{animal}/bonare-productions',
            path: {
                'animal': animal,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
