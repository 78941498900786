/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Descendent } from '../models/Descendent';
import type { Descendent2 } from '../models/Descendent2';
import type { Descendent3 } from '../models/Descendent3';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DescendentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param animal
     * @returns Descendent Get all descendents
     * @throws ApiError
     */
    public getCeresapiDescendentCget(
        animal: string,
    ): CancelablePromise<Array<Descendent>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/animals/{animal}/descendents',
            path: {
                'animal': animal,
            },
        });
    }

    /**
     * @param animal
     * @param requestBody JSON Request body
     * @returns Descendent Serialized descendent document
     * @throws ApiError
     */
    public postCeresapiDescendentPost(
        animal: string,
        requestBody?: Descendent2,
    ): CancelablePromise<Descendent> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/animals/{animal}/descendents',
            path: {
                'animal': animal,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param animal
     * @param id
     * @param requestBody JSON Request body
     * @returns Descendent Serialized descendent document
     * @throws ApiError
     */
    public putCeresapiDescendentPut(
        animal: string,
        id: string,
        requestBody?: Descendent3,
    ): CancelablePromise<Descendent> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/animals/{animal}/descendents/{id}',
            path: {
                'animal': animal,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
