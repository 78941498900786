import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React from "react";
import {useTranslation} from "react-i18next";
import {trans} from "../lib";
import Cookie from "js-cookie";
import {NavigateFunction, useNavigate} from "react-router-dom";

export const NavBar = () => {
  const { i18n } = useTranslation();
  const navigate: NavigateFunction = useNavigate()

  const onChangeLanguage = (event): void => {
    i18n.changeLanguage(event.currentTarget.id.replace('lang_', '')).then(r => {});
  }

  const onLogout = (): void => {
    Cookie.remove('token')
    navigate('/login')
  }

  return (
    <Navbar expand="lg" className="bg-body-tertiary mb-4">
      <Container>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-center">
          <Nav>
            <Nav.Link href="/animals">{trans("home")}</Nav.Link>
            {/*<Nav.Link href="/animals">{trans("animals")}</Nav.Link>*/}
            <NavDropdown title={trans("language")}>
              <NavDropdown.Item id="lang_en" onClick={onChangeLanguage}>EN</NavDropdown.Item>
              <NavDropdown.Item id="lang_ru" onClick={onChangeLanguage}>RU</NavDropdown.Item>
              <NavDropdown.Item id="lang_ro" onClick={onChangeLanguage}>RO</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link onClick={onLogout}>{trans("logout")}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;