/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Animal } from '../models/Animal';
import type { Animal2 } from '../models/Animal2';
import type { Animal3 } from '../models/Animal3';
import type { Animal4 } from '../models/Animal4';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AnimalService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param id
     * @returns Animal Get an animal
     * @throws ApiError
     */
    public getCeresapiAnimalGet(
        id: string,
    ): CancelablePromise<Animal> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/animals/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody JSON Request body
     * @returns Animal Serialized animal document
     * @throws ApiError
     */
    public putCeresapiAnimalPut(
        id: string,
        requestBody?: Animal2,
    ): CancelablePromise<Animal> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/animals/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any Delete an animal
     * @throws ApiError
     */
    public deleteCeresapiAnimalDelete(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/animals/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody JSON Request body
     * @returns Animal Serialized animal document
     * @throws ApiError
     */
    public patchCeresapiAnimalPatch(
        id: string,
        requestBody?: Animal3,
    ): CancelablePromise<Animal> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/animals/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Animal Get all animals
     * @throws ApiError
     */
    public getCeresapiAnimalCget(): CancelablePromise<Array<Animal>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/animals',
        });
    }

    /**
     * @param requestBody JSON Request body
     * @returns Animal Serialized animal document
     * @throws ApiError
     */
    public postCeresapiAnimalPost(
        requestBody?: Animal4,
    ): CancelablePromise<Animal> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/animals',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param parent
     * @returns Animal Serialized animal document
     * @throws ApiError
     */
    public patchCeresapiAnimalUpdateparent(
        id: string,
        parent: string,
    ): CancelablePromise<Animal> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/animals/{id}/parents/{parent}',
            path: {
                'id': id,
                'parent': parent,
            },
        });
    }

    /**
     * @param parent
     * @param id
     * @returns Animal Serialized animal document
     * @throws ApiError
     */
    public patchCeresapiAnimalUpdateparent1(
        parent: string,
        id: string,
    ): CancelablePromise<Animal> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/animals/{parent}/descendent/{id}',
            path: {
                'parent': parent,
                'id': id,
            },
        });
    }

}
