import axios from 'axios';
import axiosRetry from 'axios-retry';
import { request as __request } from './../api/core/request';
import {BaseHttpRequest, CancelablePromise, CeresApi, OpenAPIConfig} from '../api';
import type { ApiRequestOptions } from '../api/core/ApiRequestOptions';
import Cookie from "js-cookie";

export class AxiosHttpRequestWithRetry extends BaseHttpRequest {
  axiosInstance = axios.create();

  constructor(config: OpenAPIConfig) {
    super(config);
    axiosRetry(this.axiosInstance);
    this.axiosInstance.interceptors.response.use(response => response, this.onResponseInterceptor);
  }

  public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
    return __request(this.config, options, this.axiosInstance);
  }

  public onResponseInterceptor(error) {
    console.log('onResponseInterceptor', error)
    if (error.response.status === 403) {
      window.location.href = '/login';
    }
    if (error.response && typeof error.response.status !== 'undefined' && error.response.status === 401) {
      // Cookie.remove('token')
    }

    return Promise.reject(error);
  }
}

export const apiClient: CeresApi = new CeresApi({
  BASE: process.env.REACT_APP_API_URL,
  TOKEN: Cookie.get('token') || '',
}, AxiosHttpRequestWithRetry)

