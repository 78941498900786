import {Card, Col, Row} from "react-bootstrap";
import AsyncSelect from "react-select/async";
import React, {useEffect} from "react";
import {Animal, DocumentSexEnum} from "../../api";
import {ApiContext, trans} from "../../lib";
import Details from "./Details";

export const Parents = (props: { model: Animal }) => {
  const [model, setData] = React.useState<Animal>(props.model);
  const apiClient = React.useContext(ApiContext)

  const filterOnSearch = (animals: Animal[]) => {
    return animals
      .filter((animal: Animal) => !model.parents?.map((parent: Animal) => parent.id).includes(animal.id) && animal.id !== model.id)
      .map((animal: Animal) =>
        ({
          label: animal.regNumber,
          value: animal.id,
        })
      )
  }
  const onSearch = (inputValue: string, callback: (callbackData: any) => void) => {
    apiClient.animal.getCeresapiAnimalCget().then((response) => {
      callback([...filterOnSearch(response)]);
    })
  }

  const onChange = (value: any) => {
    apiClient.animal.patchCeresapiAnimalUpdateparent(model.id!, value.value).then((response) => {
      model.parents = response.parents;
      setData({...model});
    })

    return value;
  }

  if (!model || !model.parents) return (<></>);

  return (
    <Row>
      {[1, 2].map((i) => {
        const parent: Animal = model.parents![i - 1];

        return (
          <Col key={i}>
            <Card className={"mb-2"}>
              <Card.Header>{trans('parent')} {i}</Card.Header>
              <Card.Body>
                <AsyncSelect
                  cacheOptions
                  loadOptions={onSearch}
                  value={{
                    label: parent ? parent.regNumber : trans("noParent"),
                    value: parent ? parent.id : '',
                  }}
                  defaultOptions
                  name={`parent1${i}`}
                  onChange={onChange}
                />
                {parent && <Details model={parent}/>}
              </Card.Body>
            </Card>

            {parent && parent.parents?.length > 0 && (
              <Row>
                {parent.parents?.map((subParent: Animal) =>
                  <Col key={subParent.id}>
                    <Card className={"mb-2"}>
                      <Card.Header>{trans("sub-parent")}</Card.Header>
                      <Card.Body>
                        <Details model={subParent} xl={12} xxl={12} lg={12}/>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              </Row>
            )}
          </Col>
        )
      })}
    </Row>
  )
}

export default Parents;