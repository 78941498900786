import React, {useState} from "react";
import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import {trans} from "../../lib";
import {BonareProduction} from "../../api";
import {IconDefinition} from "@fortawesome/free-regular-svg-icons";
import moment from "moment";

type AddProps = {
  model: BonareProduction,
  onSave: (model: BonareProduction) => void,
  icon?: IconDefinition,
}

export const AddEdit = (props: AddProps) => {
  const [show, setShow] = useState(false);
  const [model, setModel]: [BonareProduction, any] = React.useState(props.model)
  const [error, setError] = useState(null)

  const onChange = (e) => {
    let value = e.target.value;
    value = e.target.type === 'number' && value.length ? parseInt(value) : value

    setModel({...model, [e.target.name]: value})
  }

  const onSave = async () => {
    try {
      await props.onSave(model);
      setShow(false);
    } catch (e) {
      setError(e.body?.error ?? e.message)
    }
  }

  return (
    <>
      <Button size="sm" variant="outline-success float-end" onClick={setShow.bind(null, true)}>
        <FontAwesomeIcon icon={props.icon ?? faAdd}/>
      </Button>

      <Modal show={show} size={"xl"} centered onHide={setShow.bind(null, false)}>
        <Modal.Header closeButton>
          <Modal.Title>{trans(`${model.id ? 'add' : 'update'} new bonare production`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant={"danger"}>{error}</Alert>}
          <Form>
            <Row>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"date"}>
                  <Form.Control type="date" name="date" value={moment(model.date).format('YYYY-MM-DD') ?? ''}
                                onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"age"}>
                  <Form.Control type="number" name="age" value={model.age ?? 0} onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"woolDensity"}>
                  <Form.Control type="string" name="woolDensity" value={model.woolDensity ?? ''} onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"woolLength"}>
                  <Form.Control type="number" name="woolLength" value={model.woolLength ?? 0} onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"woolCrimp"}>
                  <Form.Control type="number" name="woolCrimp" value={model.woolCrimp ?? 0} onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"woolQuality"}>
                  <Form.Control type="number" name="woolQuality" value={model.woolQuality ?? 0} onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"woolUniformity"}>
                  <Form.Control type="string" name="woolUniformity" value={model.woolUniformity ?? ''}
                                onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"woolExtension"}>
                  <Form.Control type="number" name="woolExtension" value={model.woolExtension ?? 0}
                                onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"woolElasticity"}>
                  <Form.Control type="number" name="woolElasticity" value={model.woolElasticity ?? 0}
                                onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"exterior"}>
                  <Form.Control type="number" name="exterior" value={model.exterior ?? 0} onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"constitution"}>
                  <Form.Control type="string" name="constitution" value={model.constitution ?? ''} onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"size"}>
                  <Form.Control type="number" name="size" value={model.size ?? 0} onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"usucColor"}>
                  <Form.Control type="string" name="usucColor" value={model.usucColor ?? ''} onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"generalNote"}>
                  <Form.Control type="number" name="generalNote" value={model.generalNote ?? 0} onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"weight"}>
                  <Form.Control type="number" name="weight" value={model.weight ?? 0} onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"woolWeight"}>
                  <Form.Control type="number" name="woolWeight" value={model.woolWeight ?? 0} onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
              <Col xs={12} sm={12} xl={6} xxl={6} lg={6}>
                <Form.FloatingLabel label={"class"}>
                  <Form.Control type="string" name="class" value={model.class ?? ""} onChange={onChange}/>
                </Form.FloatingLabel>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>

          <Button size="sm" variant="outline-success" onClick={onSave}>
            {trans('save')}
          </Button>
          <Button size="sm" variant="outline-secondary" onClick={setShow.bind(null, false)}>
            {trans('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
