import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Table, Form} from "react-bootstrap";
import moment from "moment";
import {Link} from "react-router-dom";
import {Animal, DocumentSexEnum} from "../api";
import {ApiContext, trans, useQuery} from "../lib";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faDownload, faPrint} from "@fortawesome/free-solid-svg-icons";
import {AddEdit} from "./AddEdit";
import printJS from "print-js";

export const List = () => {
  const [collection, setCollection]: [Animal[], any] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const apiClient = useContext(ApiContext)
  const queryParams = useQuery();

  useEffect(() => {
    apiClient.animal.getCeresapiAnimalCget().then((response) => {
      setCollection(response);
    })
  }, [apiClient.animal])

  const onSave = async (model: Animal) => {
    let temp = [...collection];

    let existsIndex = temp.findIndex((item: Animal) => item.id === model.id);
    if (existsIndex >= 0) {
      temp[existsIndex] = model;
    } else {
      temp.push(model);
    }

    setCollection([...temp]);
  }

  const onCheck = ({target: {id, checked}}) => {
    if (id === 'all') {
      return setCheckedRows(checked ? collection.map((item) => item.id) : []);
    } else if (checked) {
      return setCheckedRows([...checkedRows, id])
    }

    setCheckedRows(checkedRows.filter((item) => item !== id))
  }

  const idsToQuery = () => {
    return checkedRows.map((id) => `ids[]=${id}`).join('&');
  }

  const onPrint = () => {
    printJS(`${process.env.REACT_APP_API_URL}/api/v1/pdf/animals/download?${idsToQuery()}`)
  }

  return (
    <React.Fragment>
      <Card>
        <Card.Header>
          <AddEdit
              onSave={onSave}
              model={{
                birthdate: moment().format('YYYY-MM-DD'),
                sex: DocumentSexEnum.SEX_MALE
              }}
              icon={faAdd}
              showModal={queryParams.has('new')}
          />
          <Link to={`${process.env.REACT_APP_API_URL}/api/v1/pdf/animals/download?${idsToQuery()}`} target="_blank">
            <Button variant={"outline-success"} size="sm" className="me-2 float-end">
              <FontAwesomeIcon icon={faDownload}/>
            </Button>
          </Link>
          <Button variant={"outline-info"} size="sm" className="me-2 float-end" onClick={onPrint}>
            <FontAwesomeIcon icon={faPrint}/>
          </Button>
        </Card.Header>
        <Card.Body>
          <Table responsive bordered striped>
            <thead>
            <tr>
              <th>
                <Form.Check type="checkbox" id="all" className="text-center" onChange={onCheck}/>
              </th>
              <th>{trans('regNumber')}</th>
              <th>{trans('matriculNumber')}</th>
              <th>{trans('createdAt')}</th>
              <th>{trans('birthdate')}</th>
            </tr>
            </thead>
            <tbody>
            {collection && collection.map((item: Animal) =>
              (
                <tr key={item.id}>
                  <td>
                    <Form.Check onChange={onCheck} checked={checkedRows.includes(item.id)} type="checkbox" id={item.id} className="text-center"/>
                  </td>
                  <td>
                    <Link to={`/animals/${item.id}`}>{item.regNumber}</Link>
                  </td>
                  <td>
                    <Link to={`/animals/${item.id}`}>{item.matriculNumber}</Link>
                  </td>
                  <td>{moment(item.createdAt).format()}</td>
                  <td>{moment(item.birthdate).format()}</td>
                </tr>
              )
            )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}

export default List;